import React from 'react';

type Props = {
  onClick?: () => void;
  active?: boolean; // New prop
};

function TableIcon({ onClick, active }: Props) {
  return (
    <div className={`tabIcon ${active ? 'active' : ''}`} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1.4rem" height="1.4rem" viewBox="0 0 48 48">
        <path fill="#0077d4" d="M7,6h15c0.552,0,1,0.448,1,1v15c0,0.552-0.448,1-1,1H7c-0.552,0-1-0.448-1-1V7C6,6.448,6.448,6,7,6z"></path>
        <path fill="#0077d4" d="M25.042,21.958V7c0-0.552,0.448-1,1-1H41c0.552,0,1,0.448,1,1v14.958c0,0.552-0.448,1-1,1H26.042C25.489,22.958,25.042,22.511,25.042,21.958z"></path>
        <path fill="#0077d4" d="M7,25h15c0.552,0,1,0.448,1,1v15c0,0.552-0.448,1-1,1H7c-0.552,0-1-0.448-1-1V26C6,25.448,6.448,25,7,25z"></path>
        <path fill="#0077d4" d="M25,41V26c0-0.552,0.448-1,1-1h15c0.552,0,1,0.448,1,1v15c0,0.552-0.448,1-1,1H26C25.448,42,25,41.552,25,41z"></path>
      </svg>
    </div>
  );
}

export default TableIcon;
