import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Tag,
  Typography,
} from "antd";

import {
  SoundOutlined,
  EditOutlined,
  DeleteOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import UseRespon from "../../../../hook/useResponsive";
import { dispatch, useSelector } from "../../../redux/store";
import { useAuth } from "../../../context/AuthContext";
import useSpeechSynthesis from "../../../context/speech";
import { debounce } from "lodash";
import {
  deleteVocab,
  getVocabById,
  getVocabByUserId,
  putRememberByUserId,
  resetVocabDetail,
} from "../../../redux/action/vocab";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import CollapseComponent from "../../../global/Custom/CollapseComponent/CollapseComponent";
import CardComponent from "../../../global/Custom/CardComponent/CardComponent";
import TableComponent from "../../../global/Custom/TableComponent/TableComponent";
import { openNotification } from "../../../global/item/Notification/Notification";
import axios from "axios";
import ElipsCustom from "../../../../accset/svg/elips";
import TableIcon from "../../../../accset/svg/icon/TableIcon";
import CardIcon from "../../../../accset/svg/icon/CardIcon";
import { getTopicByUser } from "../../../redux/action/topic";
import { getWordform } from "../../../redux/action/grammar/wordform";
import Paragraph from "antd/es/typography/Paragraph";
import Loading from "../../../global/item/Loading/loading";

const { Option } = Select;
interface CategoryColors {
  "Tính từ": { text: string; background: string };
  "Trạng từ": { text: string; background: string };
  "Danh từ": { text: string; background: string };
  "Động từ": { text: string; background: string };
  "Đại từ": { text: string; background: string };
  "Giới từ": { text: string; background: string };
  "Liên từ": { text: string; background: string };
}
type DebouncedFunction = {
  (...args: any[]): void; // Accept any number of arguments
  cancel: () => void; // Include the cancel method
};
function VocabRemember() {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const { vocabList, vocabDetail, totalVocab } = useSelector(
    (state) => state.vocab
  );
  const [showTable, setShowTable] = useState(true);
  const { wordformList } = useSelector((state) => state.wordform);
  const { topicList } = useSelector((state) => state.topic);
  const state = useAuth();
  const user = state.user;
  const [loading, setLoading] = useState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedValuesTopic, setSelectedValuesTopic] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedIdDetail, setSelectedIdDetail] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const { speakText } = useSpeechSynthesis();
  const [params, setParams] = useState({
    pageIndex: 1,
    pageSize: 12,
  });
  const debouncedSearchRef = useRef<DebouncedFunction | null>(null);
  useEffect(() => {
    // Create the debounced function only once
    debouncedSearchRef.current = debounce(
      (userId, search, categoryIds, topicIds, isRemember = true) => {
        if (userId) {
          dispatch(
            getVocabByUserId({
              userId,
              search,
              categoryIds,
              topicIds,
              isRemember,
            })
          );
        }
      },
      500
    );
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    // Call the debounced function with the latest values
    debouncedSearchRef.current?.(
      user?._id,
      searchValue,
      selectedValues,
      selectedValuesTopic
    );

    // Cleanup function to cancel the debounce on unmount
    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [
    user?._id,
    searchValue,
    selectedValues,
    selectedValuesTopic,
  ]);

  useEffect(() => {
    if (selectedIdDetail) {
      dispatch(getVocabById(selectedIdDetail));
    }
    dispatch(getWordform({}));
    if (user?._id) {
      dispatch(getTopicByUser(user._id));
    }
  }, [selectedIdDetail, user?._id]);

  const showModal = (id: any) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };
  const showModalDetail = (id: any) => {
    setIsModalDetailOpen(true);
    setSelectedIdDetail(id);
  };

  const handleOkDelete = async () => {
    try {
      if (selectedId) {
        await dispatch(deleteVocab(selectedId));
        openNotification({
          type: "success",
          message: t("success"),
        });
      }
      setSelectedId(null);
    } catch (error) {
      openNotification({
        type: "error",
        message: t("error"),
      });
    } finally {
      setIsModalOpen(false); // Close the modal
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedId(null);
  };
  const handleCancelDetail = () => {
    setIsModalDetailOpen(false);
    setSelectedIdDetail(null);
    dispatch(resetVocabDetail());

  };
  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };
  const handleSwitchChange = async (checked: boolean, key: string) => {
    setLoading(true)
    await dispatch(putRememberByUserId(key));
    const userId = user?._id;
    if (userId) {
      await dispatch(
        getVocabByUserId({
          userId,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          isRemember: true,
          search: searchValue || "", 
          topicIds: selectedValuesTopic.length > 0 ? selectedValuesTopic : [],
          categoryIds: selectedValues.length > 0 ? selectedValues : [],
        })
      );
      setLoading(false)
    }
  };
  const onChangePage = (pageIndex: number, pageSize: number) => {
    console.log(`Page: ${pageIndex}, Page Size: ${pageSize}`);
    setParams({
      pageIndex,
      pageSize,
    });

    if (user) {
      dispatch(
        getVocabByUserId({
          userId: user._id,
          pageIndex,
          pageSize,
          isRemember: true,
          search: searchValue || "", 
          topicIds: selectedValuesTopic.length > 0 ? selectedValuesTopic : [],
          categoryIds: selectedValues.length > 0 ? selectedValues : [],
        })
      );
    }
  };
  const handleCollapseChange = (text: any) => {
    if ("speechSynthesis" in window) {
      console.log("Speaking:", text);
      speakText(text);
    } else {
      console.log("SpeechSynthesis is not supported in this browser.");
    }
  };
  const handleCollapse = (key: any) => {
    if ("speechSynthesis" in window) {
      const activePanel = items.find(
        (item) => item.key === key[key.length - 1]
      );
      if (activePanel) {
        console.log("Speaking:", activePanel.textToRead);
        speakText(activePanel.textToRead);
      } else {
        console.log("Active panel not found.");
      }
    } else {
      console.log("SpeechSynthesis is not supported in this browser.");
    }
  };
  const handleExcel = async () => {
    if (user?._id) {
      try {
        // Directly call the API using axios
        const response = await axios.post(
          "https://vivpepifood.click/vocab/export-excel",
          { userId: user._id,isRemember:true },

          { responseType: "arraybuffer" } // Specify that the response will be an ArrayBuffer
        );

        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Từ vựng đã nhớ.xlsx"); // Specify the file name
        document.body.appendChild(link);
        link.click();

        // Cleanup
        if (link.parentNode) {
          link.parentNode.removeChild(link); // Ensure parentNode exists before removing
        }
        window.URL.revokeObjectURL(url); // Clean up the URL object
      } catch (error) {
        console.error("Error exporting Excel file:", error);
      }
    }
  };
  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };
  const handleSelectChangeTopic = (values: string[]) => {
    setSelectedValuesTopic(values);
  };
  const itemsDrop = (id: any) => [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            console.log("Clicked item ID:", id);
            showModalDetail(id);
          }}
        >
          <span style={{ marginBottom: 20 }}>
            <CalendarOutlined style={{ marginRight: 8 }} />
            {t("detail")}
          </span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ marginBottom: 20 }}>
          <EditOutlined style={{ marginRight: 8 }} />
          {t("edit")}
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={() => showModal(id)}>
          <span>
            <DeleteOutlined style={{ marginRight: 8 }} />
            {t("delete")}
          </span>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: t("vocab"),
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text: string, record: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
          onClick={() => handleCollapseChange(text)}
          onMouseEnter={(e) => (e.currentTarget.style.color = "blue")}
          onMouseLeave={(e) => (e.currentTarget.style.color = "black")}
        >
          <span style={{ marginRight: "8px", fontWeight: 400 }}>{text}</span>
          <SoundOutlined
            style={{ cursor: "pointer" }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleCollapseChange(text);
            }}
          />
        </div>
      ),
    },
    {
      title: t("transcrip"),
      dataIndex: "transcription",
      key: "transcription",
      width: "22%",
    },
    {
      title: t("wordform"),
      dataIndex: "wordform",
      key: "wordform",
      width: "22%",
      render: (text: string) => {
        // Split the wordform into individual category names
        const categoryNames = text.split(", ").map((name) => name.trim());

        return (
          <div>
            {categoryNames.map((name: string, index: number) => (
              <div
                key={index}
                style={{
                  color:
                    categoryColors[name as keyof CategoryColors]?.text ||
                    "#000000",
                  backgroundColor:
                    categoryColors[name as keyof CategoryColors]?.background ||
                    "#FFFFFF",
                  padding: "3px 8px",
                  borderRadius: "10px",
                  display: "inline-block", // Ensure the categories are displayed in a row
                  marginRight: index < categoryNames.length - 1 ? "10px" : "0",
                
                }}
              >
                {name}
              </div>
            ))}
          </div>
        );
      },
    },

    {
      title: t("meaning"),
      dataIndex: "meaning",
      key: "meaning",
      width: "20%",
      render: (text: string) => (
        <div
          className="truncate-two-lines"
          dangerouslySetInnerHTML={{ __html: text }} // Hiển thị HTML
        />
      ),
    },
    {
      title: t("Trạng thái"),
      dataIndex: "isRemember",
      key: "meaning",
      width: "19%",
      render: (isRemember: any) => {
        let text = "Từ mới"; // Giá trị mặc định
        let textColor = statusColor["Từ mới"].text; // Màu chữ mặc định
        let backgroundColor = statusColor["Từ mới"].background; // Màu nền mặc định

        if (isRemember === true) {
          text = "Đã nhớ";
          textColor = statusColor["Đã nhớ"].text;
          backgroundColor = statusColor["Đã nhớ"].background;
        } else if (isRemember === false) {
          text = "Chưa nhớ";
          textColor = statusColor["Chưa nhớ"].text;
          backgroundColor = statusColor["Chưa nhớ"].background;
        }

        return (
          <div
            style={{
              color: textColor,
              backgroundColor: backgroundColor,
              padding: "3px 8px",
              borderRadius: "10px",
              display: "inline-block",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: t("Đã nhớ"),
      dataIndex: "isRemember",
      key: "isRemember",
      width: "15%",
      render: (text: string, record: any) => (
        <div style={{ width: "50px", margin: "0 auto" }}>
          <Switch
            checked={record.isRemember === true} // Check if isRemember is true
            onChange={(checked) => handleSwitchChange(checked, record.key)} // Handle the change
          />
        </div>
      ),
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (text: string, record: any) => (
        <div style={{ width: "50px", margin: "0 auto" }}>
          <Dropdown menu={{ items: itemsDrop(record.key) }} trigger={["hover"]}>
            <span>
              <ElipsCustom />
            </span>
          </Dropdown>
        </div>
      ),
    },
  ];
  const data = vocabList.map((item) => {
    const categoryNames = item.category
      .map((category) => category.name)
      .join(", ");

    return {
      key: item._id,
      name: item.name,
      wordform: categoryNames,
      transcription: item.transcription,
      meaning: item.meaning,
      isRemember: item.isRemember,
      example: item.example,
    };
  });

  const options = wordformList.map((item: any) => ({
    value: item._id,
    label: item.name,
  }));
  const optionsTopic = topicList.map((item: any) => ({
    value: item._id,
    label: item.name,
  }));

  const items = vocabList.map((vocab) => ({
    key: vocab._id,
    label: `${vocab.name} [${vocab.transcription}] `,
    children: (
      <div>
        <p style={{ margin: "0 0 10px 0" }}>
          <strong>Nghĩa của từ:</strong>
          <span dangerouslySetInnerHTML={{ __html: vocab.meaning }} />
        </p>

        <p style={{ margin: "0 0 10px 0" }}>
          <strong>Example:</strong>
          <span dangerouslySetInnerHTML={{ __html: vocab.example }} />
        </p>
        <Button type="primary" onClick={() => showModalDetail(vocab._id)}>
          Chi tiết
        </Button>
      </div>
    ),
    textToRead: vocab.name,
  }));

  // Color
  const colors = ["blue", "green", "red", "purple", "orange"];
  const categoryColors: CategoryColors = {
    "Tính từ": { text: "#FF6F61", background: "#FFE9E6" },
    "Trạng từ": { text: "#6BBF74", background: "#E4F8E4" },
    "Danh từ": { text: "#4B6DFF", background: "#E0E8FF" },
    "Động từ": { text: "#9B59B6", background: "#F3E5F5" },
    "Đại từ": { text: "#F47F7F", background: "#FEECEC" },
    "Giới từ": { text: "#5A9BF4", background: "#E4F1FF" },
    "Liên từ": { text: "#6BFC6B", background: "#E2FCE2" },
  };
  const statusColor = {
    "Đã nhớ": {
      text: "#28a745",
      background: "#d4edda",
    },
    "Chưa nhớ": {
      text: "#dc3545",
      background: "#f8d7da",
    },
    "Từ mới": {
      text: "#17a2b8",
      background: "#d1ecf1",
    },
  };
  return (
    <div>
{loading?<Loading/>:<div>
      <TitleComponent
        title={t("Từ vựng đã nhớ")}
        type="primary"
        isExist={false}
      />
      {isMobile ? (
        <div>
          <Row gutter={[20, 0]}>
            <Col xs={12}>
              <Input
                placeholder="Tìm kiếm của bạn"
                value={searchValue}
                onChange={handleInputChange}
                className="inputFormCustom"
              />
            </Col>
            <Col xs={12}>
              <Select
                mode="multiple"
                value={selectedValues}
                placeholder="Chọn từ loại"
                className="selectFormFilter"
                style={{ width: "100%", border: "none" }}
                onChange={handleSelectChange}
              >
                {options.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <CollapseComponent
            data={data}
            totalPage={totalVocab}
            onChangePage={onChangePage}
            pageIndex={params.pageIndex}
            pageSize={params.pageSize}
            handleCollapse={handleCollapse}
            exportOnClick={handleExcel}
            showModalDetail={showModalDetail}
          />
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: 20 }}>
            <Row gutter={[20, 0]}>
              <Col xl={6}>
                <Input
                  placeholder="Tìm kiếm của bạn"
                  value={searchValue} // Gán giá trị từ state
                  onChange={handleInputChange} // Gọi hàm khi giá trị thay đổi
                  className="inputFormCustom"
                />
              </Col>
              <Col xl={6}>
                <Select
                  mode="multiple"
                  value={selectedValues}
                  placeholder="Chọn từ loại"
                  className="selectFormFilter"
                  style={{ width: "100%", border: "none" }}
                  onChange={handleSelectChange}
                >
                  {options.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xl={6}>
                <Select
                  mode="multiple"
                  value={selectedValuesTopic}
                  placeholder="Chọn chủ đề"
                  className="selectFormFilter"
                  style={{ width: "100%" }}
                  onChange={handleSelectChangeTopic}
                >
                  {optionsTopic.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xl={6}>
                <Flex justify="flex-end" gap={5}>
                  <TableIcon
                    onClick={() => setShowTable(true)}
                    active={showTable}
                  />
                  <CardIcon
                    onClick={() => setShowTable(false)}
                    active={!showTable}
                  />
                </Flex>
              </Col>
            </Row>
          </div>
          {showTable ? (
            <TableComponent
              exportOnClick={handleExcel}
              columns={columns}
              data={data}
              totalPage={totalVocab}
              onChangePage={onChangePage}
              pageIndex={params.pageIndex}
              pageSize={params.pageSize}
            />
          ) : (
            <CardComponent
              exportOnClick={handleExcel}
              data={data}
              onCardClick={showModalDetail}
              handleCollapseChange={handleCollapseChange}
              totalPage={totalVocab}
              onChangePage={onChangePage}
              pageIndex={params.pageIndex}
              pageSize={params.pageSize}
            />
          )}
        </div>
      )}
      <Modal
        title="Xóa từ"
        open={isModalOpen}
        centered
        footer={false}
        width={330}
        onCancel={handleCancel}
        zIndex={99999}
      >
        <p>Bạn có chắc chắn xoá từ này không?</p>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button style={{ padding: "0 20px" }} onClick={handleCancel}>
            Hủy
          </Button>
          <Button type="primary" onClick={handleOkDelete}>
            Tiếp tục
          </Button>
        </div>
      </Modal>

      <Modal
        open={isModalDetailOpen}
        centered
        footer={false}
        width={1000}
        onCancel={handleCancelDetail}
        zIndex={99999}
        closeIcon={false}
        style={isMobile ? { padding: 16 } : undefined}
      >
        {isMobile ? (
          <div>
            <div className="animated-border">
              <Row style={{ alignItems: "center" }}>
                <Typography.Title
                  level={2}
                  style={{
                    margin: 0,
                    fontSize: 22,
                    fontWeight: 700,
                    color: "var(--primary-color)",
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 6,
                    textTransform: "capitalize",
                  }}
                >
                  {vocabDetail.name}
                </Typography.Title>
                <Col xs={18}>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "18px",
                    }}
                  >
                    {vocabDetail.transcription}
                  </p>
                  <div>
                    {vocabDetail.category.map((category, index) => (
                      <Tag
                        key={index}
                        color={colors[index % colors.length]}
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          marginTop: "8px",
                          marginRight: "4px",
                        }}
                      >
                        {category.name}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col xs={6}>
                  <Flex justify="flex-end">
                    <SoundOutlined
                      style={{ cursor: "pointer" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleCollapseChange(vocabDetail.name);
                      }}
                    />
                  </Flex>
                </Col>
              </Row>
              <div className="animated-border-inner"></div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <div className="animated-border">
              <Row style={{ alignItems: "center" }}>
                <Col xl={12}>
                  <Flex align="center" gap={20}>
                    <Typography.Title
                      level={2}
                      style={{
                        margin: 0,
                        fontSize: "28px",
                        fontWeight: 700,
                        color: "var(--primary-color)",
                      }}
                    >
                      {vocabDetail.name}
                    </Typography.Title>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "18px",
                      }}
                    >
                      {vocabDetail.transcription}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        transition: "color 0.3s",
                      }}
                      onClick={() => handleCollapseChange(vocabDetail.name)}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.color = "blue")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = "black")
                      }
                    >
                      <SoundOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleCollapseChange(vocabDetail.name);
                        }}
                      />
                    </div>
                  </Flex>
                  <div>
                    {vocabDetail.category.map((category, index) => (
                      <Tag
                        key={index}
                        color={colors[index % colors.length]}
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          marginTop: "8px",
                          marginRight: "4px",
                        }}
                      >
                        {category.name}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col xl={12}>
                  {/* <Flex justify="flex-end">
                    <Button type="primary">{topic?.name}</Button>
                  </Flex> */}
                </Col>
              </Row>
              <div className="animated-border-inner"></div>
            </div>
          </div>
        )}

        <Row gutter={[16, 0]} style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {vocabDetail.meaning && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("meaning")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    lineHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: vocabDetail.meaning }}
                />
              </Card>
            </Col>
          )}

          {vocabDetail.example && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("example")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    lineHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: vocabDetail.example }}
                />
                <SoundOutlined
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleCollapseChange(vocabDetail.example);
                  }}
                />
              </Card>
            </Col>
          )}

          {vocabDetail.synonym && (
            <Col xl={12} xs={24}>
              <Card
                title={
                  <span style={{ color: "#5c9aff" }}>{t("synonymo")}</span>
                }
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.synonym}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.antonym && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("antonym")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.antonym}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.phrase && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("phrase")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.phrase}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.context && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("context")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,

                  overflow: "hidden", // Hide overflow
                }}
              >
                <Paragraph style={{ margin: 0, overflowY: "auto" }}>
                  {vocabDetail.context}
                </Paragraph>
              </Card>
            </Col>
          )}

          {vocabDetail.note && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("note")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: "200px",
                  overflow: "hidden", // Hide overflow
                }}
              >
                <div
                  className="div_tag"
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    lineHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: vocabDetail.note }}
                />
              </Card>
            </Col>
          )}

          {vocabDetail.image && (
            <Col xl={12} xs={24}>
              <Card
                title={<span style={{ color: "#5c9aff" }}>{t("image")}</span>}
                bordered={false}
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "8px",
                  height: isMobile ? 150 : 200,
                }}
              >
                <img
                  src={vocabDetail.image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",

                    borderRadius: "8px",
                  }}
                  loading="lazy"
                />
              </Card>
            </Col>
          )}
        </Row>
      </Modal>
    </div>}
    
    </div>
  );
}

export default VocabRemember;
