import React from "react";
import { Menu } from "antd";
import "./Slider.scss";
import { useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  PicCenterOutlined,
  DeploymentUnitOutlined,
  ExperimentOutlined,
  AlertOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const SliderApp = ({ onClick }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleMenuClick = ({ key }: any) => {
    onClick(key);
    navigate(`/${key}`);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["1"]}
      onClick={handleMenuClick}
      className="sliderApp"
    >
      <Menu.Item
        key="header"
        disabled
        style={{ fontWeight: "bold", cursor: "default" }}
      >
        {t("overview")}
      </Menu.Item>
      <Menu.Item key="" icon={<HomeOutlined />}>
        {t("dashboard")}
      </Menu.Item>
      <Menu.Item
        key="header"
        disabled
        style={{ fontWeight: "bold", cursor: "default" }}
      >
        {t("manage")}
      </Menu.Item>
      <SubMenu
        key="word"
        style={{ background: "none" }}
        icon={<DeploymentUnitOutlined />}
        title={t("word")}
      >
        <Menu.Item icon={<CalendarOutlined />} key="Vocab">
          {t("vocab")}
        </Menu.Item>
        <Menu.Item icon={<AlertOutlined />} key="Vocab/Remember">
          {t("Đã nhớ")}
        </Menu.Item>
        <Menu.Item icon={<WarningOutlined />} key="Vocab/NotRemember">
          {t("Chưa nhớ")}
        </Menu.Item>
        <Menu.Item icon={<ExperimentOutlined />} key="Phrase">
          {t("phraseVerb")}
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="grammar"
        style={{ background: "none" }}
        icon={<AppstoreOutlined />}
        title={t("grammar")}
      >
        <Menu.Item key="Grammar/Wordform" className="menuitemSub">
          {t("wordform")}
        </Menu.Item>
        <Menu.Item key="home2" className="menuitemSub">
          Home 2
        </Menu.Item>
      </SubMenu>

      <Menu.Item icon={<PicCenterOutlined />} key="Topic">
        {t("topic")}
      </Menu.Item>
      <Menu.Item icon={<PicCenterOutlined />} key="Review">
        {t("review")}
      </Menu.Item>
    </Menu>
  );
};

export default SliderApp;
