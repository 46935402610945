import { Button, Card, Col, Flex, Row, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import HeartNoactive from "../../../../accset/svg/heart/HeartNoactive";
import { dispatch } from "../../../redux/store";
import {
  getVocabByUserId,
  putRememberByUserId,
} from "../../../redux/action/vocab";
import CustomPagination from "../pagination-custom/PaginationCustom";
import { useAuth } from "../../../context/AuthContext";
import { DownloadOutlined } from "@ant-design/icons";

// Define a color mapping for each category name
const categoryColors: Record<string, string> = {
  "Tính từ": "magenta",
  "Trạng từ": "green",
  "Danh từ": "blue",
  "Động từ": "orange",
  "Đại từ": "red",
  "Giới từ": "purple",
  "Liên từ": "cyan", // Add more colors as needed
};

type Item = {
  name: string;
  transcription: string;
  wordform?: string;
  meaning: string;
  key: string;
  isRemember: boolean;
  
};

type Props = {
  data: Item[];
  totalPage?: number;
  onCardClick: (id: string) => void;
  handleCollapseChange: (text: string) => void;
  onChangePage: (pageIndex: number, pageSize: number) => void;
  exportOnClick?: () => void;
  pageIndex: number;
  pageSize: number;
  search?: string;
  categoryId?: string[]; // Updated type for categoryId
  topicId?: string[];
};

function CardComponent({
  data,
  onCardClick,
  handleCollapseChange,
  totalPage,
  onChangePage,
  exportOnClick,
  pageIndex,
  pageSize,
  search,
  categoryId,
  topicId,
}: Props) {
  const state = useAuth();
  const userId = state.user?._id;
  const [activeHearts, setActiveHearts] = useState<Set<string>>(new Set());
  const [params, setParams] = useState({
    pageSize: pageSize,
    pageIndex: pageIndex,
  });

  useEffect(() => {
    const initialActiveHearts = new Set<string>(
      data.filter((item) => item.isRemember).map((item) => item.key)
    );
    setActiveHearts(initialActiveHearts);
  }, [data]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      pageIndex: pageIndex,
    }));
  }, [pageIndex]);

  const handleHeartClick = async (id: string) => {
    setActiveHearts((prev) => {
      const updatedHearts = new Set(prev);
      if (updatedHearts.has(id)) {
        updatedHearts.delete(id);
      } else {
        updatedHearts.add(id);
      }
      return updatedHearts;
    });

    await dispatch(putRememberByUserId(id));
    if (userId) {
      await dispatch(
        getVocabByUserId({
          userId,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          search: search,
          categoryIds: categoryId,
          topicIds: topicId,
        })
      );
    }
  };

  const truncateTranscription = (transcription: string) => {
    const spaceIndex = transcription.indexOf(" ");
    return spaceIndex !== -1
      ? transcription.substring(0, spaceIndex)
      : transcription; // Truncate transcription
  };

  const handlePageChange = (newPageIndex: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: newPageIndex,
    }));
    onChangePage(newPageIndex, params.pageSize); // Use params.pageSize instead of pageSize
  };

  const handleSelectChange = (value: number) => {
    setParams((prev) => ({
      ...prev,
      pageSize: value,
    }));
    onChangePage(params.pageIndex, value);
  };

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Button
          type="dashed"
          icon={<DownloadOutlined />}
          onClick={exportOnClick}
        >
          Xuất file
        </Button>
        <Flex justify="flex-end" style={{ margin: "10px 0" }}>
          <div className="table__footer__col">
            <p className="table__footer__col__title">Số hàng mỗi trang</p>
            <Select
              className="table__footer__select"
              style={{ width: 58 }}
              value={params.pageSize}
              onChange={handleSelectChange}
              bordered={false}
              suffixIcon={null}
            >
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={12}>12</Select.Option>
              <Select.Option value={20}>20</Select.Option>
            </Select>
            <CustomPagination
              current={params.pageIndex}
              total={totalPage}
              pageSize={params.pageSize}
              onChange={handlePageChange}
            />
          </div>
        </Flex>
      </Flex>

      <Row
        gutter={[20, 20]}
        style={{ padding: "10px 0", maxHeight: 400, overflow: "auto" }}
      >
        {data.map((item, index) => {
          const wordforms = item.wordform ? item.wordform.split(", ") : [];

          return (
            <Col key={item.key} xl={6}>
              <Card
                className="cardVocab"
                onClick={() => {
                  onCardClick(item.key);
                  handleCollapseChange(item.name);
                }}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <Flex justify="space-between">
                    <div style={{ display: "flex", alignItems: "center", flexWrap:"wrap" }}>
                      <h3
                        style={{
                          margin: "0 8px 0 0",
                          color: `var(--primary-color)`,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "160px",
                        }}
                      >
                        {item.name}
                      </h3>

                      <p style={{ margin: 0 }}>
                        {truncateTranscription(item.transcription)}
                      </p>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleHeartClick(item.key);
                      }}
                    >
                      <HeartNoactive isActive={activeHearts.has(item.key)} />
                    </div>
                  </Flex>
                  {wordforms.map((form, formIndex) => (
  <Tag key={formIndex} color={categoryColors[item.wordform || "default"]}>
    {form.trim()}
  </Tag>
))}

                </div>
                <div style={{ marginTop: "8px", maxHeight: 50 }}>
                  <p
                    dangerouslySetInnerHTML={{ __html: item.meaning }}
                    style={{
                      margin: 0,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                    }}
                  />
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default CardComponent;
