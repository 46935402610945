import { Button, Collapse, Flex,  } from "antd";
import React, { useEffect, useState } from "react";
import CustomPagination from "../pagination-custom/PaginationCustom";
import { DownloadOutlined } from "@ant-design/icons";

import { useAuth } from "../../../context/AuthContext";
import { dispatch } from "../../../redux/store";
import { getVocabByUserId, putRememberByUserId } from "../../../redux/action/vocab";
import HeartNoactive from "../../../../accset/svg/heart/HeartNoactive";

type Item = {
  name: string;
  transcription: string;
  meaning: string;
  key: string;
  example: string;
  isRemember: boolean;

};

type Props = {
  data: Item[];
  totalPage?: number;
  handleCollapse: (key: any) => void;
  showModalDetail: (key: string) => void
  onChangePage: (pageIndex: number, pageSize: number) => void;
  exportOnClick?: () => void;
  pageIndex: number;
  pageSize: number;
};

function CollapseComponent({
  data,
  handleCollapse,
  totalPage,
  onChangePage,
  exportOnClick,
  pageIndex,
  pageSize,
  showModalDetail
}: Props) {
  const state = useAuth();
  const userId = state.user?._id
  const { Panel } = Collapse;
  const [activeHearts, setActiveHearts] = useState<Set<string>>(new Set());
  const [params, setParams] = useState({
    pageSize: pageSize,
    pageIndex: pageIndex,
  });
  useEffect(() => {
    const initialActiveHearts = new Set<string>(
      data.filter((item) => item.isRemember).map((item) => item.key)
    );
    setActiveHearts(initialActiveHearts);
  }, [data]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      pageIndex: pageIndex,
    }));
  }, [pageIndex]);

  const handlePageChange = (newPageIndex: number, pageSize: number) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: newPageIndex,
    }));
    onChangePage(newPageIndex, params.pageSize);
  };
  const handleHeartClick = async (id: string) => {
    setActiveHearts((prev) => {
      const updatedHearts = new Set(prev);
      if (updatedHearts.has(id)) {
        updatedHearts.delete(id);
      } else {
        updatedHearts.add(id);
      }
      return updatedHearts;
    });

    await dispatch(putRememberByUserId(id));
    if (userId) {
      await dispatch(
        getVocabByUserId({
          userId,
          pageIndex: params.pageIndex, // Duy trì pageIndex
          pageSize: params.pageSize, // Duy trì pageSize
        })
      );
    }
  };
  const items = data.map((vocab) => ({
    key: vocab.key,
    label: (
      <div style={{ display: "flex",alignItems:'center', justifyContent:"space-between", width:"100%"}}>
        <span style={{ marginRight: "8px" }}>{`${vocab.name} [${vocab.transcription}]`}</span>
        <div
          onClick={(e) => {
            e.stopPropagation(); // Prevent the collapse from triggering
            handleHeartClick(vocab.key);
          }}
        >
          <HeartNoactive isActive={activeHearts.has(vocab.key)} />
        </div>
      </div>
    ),
    children: (
      <div>
        <p style={{ margin: "0 0 10px 0" }}>
          <strong>Nghĩa của từ:</strong>
          <span dangerouslySetInnerHTML={{ __html: vocab.meaning }} />
          
        </p>
        <p style={{ margin: "0 0 10px 0" }}>
          <strong>Example:</strong>
          <span dangerouslySetInnerHTML={{ __html: vocab.example }} />
        </p>
        <Button type="primary" onClick={() => showModalDetail(vocab.key)}>
          Chi tiết
        </Button>
      </div>
    ),
    textToRead: vocab.name,
  }));

  return (
    <div>
      <Flex justify="space-between" align="center">
      <Button type="text" icon={<DownloadOutlined />} onClick={exportOnClick}>
            Xuất file
          </Button>
        <Flex justify="flex-end" style={{ margin: "10px 0" }}>
          <div className="table__footer__col">
            <CustomPagination
              current={params.pageIndex}
              total={totalPage}
              pageSize={params.pageSize}
              onChange={handlePageChange}
            />
          </div>
        </Flex>
      </Flex>
      <div style={{
         maxHeight: "59vh",
         overflow: "auto",
      }}>
        {items.map((item) => (
          <Collapse
            defaultActiveKey={["1"]}
            onChange={handleCollapse}
            style={{
              background: "none",
              border: "none",
              display:"flex",
              alignItems:"center"
            }}
          >
            <Panel header={item.label} key={item.key}>
              {item.children}
            </Panel>
          </Collapse>
        ))}
      </div>
    </div>
  );
}

export default CollapseComponent;
