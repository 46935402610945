import React from "react";

import { useTranslation } from "react-i18next";
import VocabForm from "./VocabForm";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";

function VocabUpdate() {
    const {t} = useTranslation()
  return (
    <div>
      <TitleComponent title={t('update')} isExist={false} />
      <VocabForm isEdit={true}/>
    </div>
  );
}

export default VocabUpdate;
