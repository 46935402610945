import { AxiosResponse } from "axios";
import { getAsync, postAsync } from "./client";

class TopicService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/topic', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; 
    }
  }

  async getById(topicId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/topic/${topicId}`);
      return response;
    } catch (error) {
      console.error(`Error fetching topic with ID ${topicId}:`, error);
      throw error; 
    }
  }

  async post(formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await postAsync('/topic', formData);
      return response;
    } catch (error) {
      console.error("Error posting topic data:", error);
      throw error; 
    }
  }
  async getByUser(userId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/topic/user/${userId}`);
      return response;
    } catch (error) {
      console.error(`Error fetching vocab with ID ${userId}:`, error);
      throw error; 
    }
  }
}

// Create an instance of TopicService
const topicServiceInstance = new TopicService();

export default topicServiceInstance;
