import React, { createContext, useContext, useReducer, useEffect, useCallback, ReactNode } from "react";
import { openNotification } from "../global/item/Notification/Notification";
import { useTranslation } from "react-i18next";
import authService from "../service/auth";
import { jwtDecode } from "jwt-decode";

interface User {
  _id: string;
  email: string;
  username: string;
  mustTakeTest:boolean;
  vocabCount:number;
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  errorMessage: string | null;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (values: { email: string; password: string; authMethod: string }) => Promise<void>;
  loginGoogle: (credentialResponse: any) => Promise<void>;
  validateToken: () => Promise<void>;
  clearErrorMessage: () => void;
  hasValidToken: boolean;
}

type Action =
  | { type: "INITIAL"; payload: AuthState }
  | { type: "LOGIN"; payload: { user: User } }
  | { type: "LOGOUT" }
  | { type: "SET_ERROR"; payload: string | null };

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIAL":
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        errorMessage: null,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        errorMessage: null,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        errorMessage: null,
      };
    case "SET_ERROR":
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const validateToken = useCallback(async () => {
    const token = localStorage.getItem("token");
    
    if (token) {
      try {
        const response = await authService.getToken(token);
        const userData = response.data;
        dispatch({ type: "INITIAL", payload: { isAuthenticated: true, user: userData.user, errorMessage: null } });
      } catch (error) {
        console.error("Token validation failed", error);
        localStorage.removeItem("token");
        dispatch({ type: "LOGOUT" });
        dispatch({ type: "SET_ERROR", payload: "Token validation failed. Please log in again." });
      }
    } else {
      dispatch({ type: "LOGOUT" });
      dispatch({ type: "SET_ERROR", payload: "No token found. Please log in." });
    }
  }, []);

  const login = useCallback(async (values: { email: string; password: string; authMethod: string }) => {
    try {
      const response = await authService.login(values);
      const { token, user } = response.data;
      localStorage.setItem("token", token);
      dispatch({ type: "LOGIN", payload: { user } });
      validateToken()
      openNotification({
        type: "success",
        message: t("success"),
        description: t("LoginSuccess"),
      });
    } catch (error) {
      console.error("Login failed", error);
      dispatch({ type: "SET_ERROR", payload: "Login failed. Please check your credentials." });
      throw error; // Re-throw to be caught in the component
    }
  }, [validateToken,t]);

  const loginGoogle = useCallback(async (credentialResponse: any) => {
    const { credential } = credentialResponse;

    if (credential) {
      try {
        const decoded: any = jwtDecode(credential);
        const { email, name } = decoded;

        const response = await authService.loginGoogle({
          email,
          authMethod: "google",
          username: name,
        });

        openNotification({
          type: "success",
          message: t("success"),
          description: t("LoginSuccess"),
        });

        const { token } = response.data;
        if (token) {
          localStorage.setItem("token", token);
          dispatch({ type: "LOGIN", payload: { user: response.data.user } });
          validateToken();
        }
      } catch (error) {
        console.error("Google login failed", error);
        openNotification({
          type: "error",
          message: t("error"),
          description: t("loginFailure"),
        });
      }
    }
  }, [validateToken, t]);



  const clearErrorMessage = () => {
    dispatch({ type: "SET_ERROR", payload: null });
  };

  const hasValidToken = state.isAuthenticated;

  useEffect(() => {
    validateToken();
    window.addEventListener('storage', validateToken);
    return () => {
      window.removeEventListener('storage', validateToken);
    };
  }, [validateToken]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user, // Directly providing user
        login,
        loginGoogle,
        validateToken,
        clearErrorMessage,
        hasValidToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
