import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import vocab from "../../service/vocab";
import { VocabState } from "../../@type/vocab";


export const getVocabByUserId = createAsyncThunk(
  "get/getVocabByUserId",
  async ({
    userId,
    pageIndex = 1,
    pageSize = 12,
    search = '',
    categoryIds = [],
    topicIds = [],
    isRemember
  }: {
    userId: string;
    pageIndex?: number;
    pageSize?: number;
    search?: string;
    categoryIds?: string[];
    topicIds?: string[];
    isRemember?: boolean | null; // Include isRemember as an optional boolean or null
  }) => {
    const { data } = await vocab.getByUserId(userId, pageIndex, pageSize, search, categoryIds, topicIds, isRemember);
    return data; // Adjust the return to include vocabList, totalPages, etc.
  }
);


export const getVocab = createAsyncThunk(
  "get/getVocab",
  async (params: any) => {
    const { data } = await vocab.get(params);
    return data;
  }
);
export const getVocabById = createAsyncThunk(
  "get/getVocabById",
  async (id: any) => {
    const { data } = await vocab.getById(id);
    return data;
  }
);
export const putRememberByUserId = createAsyncThunk(
  "put/putRememberByUserId",
  async (id: any) => {
    const { data } = await vocab.getPutRememberByUserId(id);
    return data;
  }
);
export const getVocabQuiz = createAsyncThunk(
  "get/getVocabQuiz",
  async ({
    userId,
    amount = 10,  // Amount có thể là number
    categoryIds = [],
    topicIds = [],
  }: {
    userId: string;
    amount?: number;
    categoryIds?: string[];
    topicIds?: string[];
  }) => {
    // Gọi hàm getVocabQuiz với body
    const { data } = await vocab.getVocabQuiz(userId, {
      amount,
      category: categoryIds,
      topic: topicIds
    });
    return data;
  }
);

export const createVocab = createAsyncThunk(
  "get/createVocab",
  async (formData: FormData) => {
    const { data } = await vocab.post(formData);
    return data;
  }
);
export const deleteVocab = createAsyncThunk(
  "delete/deleteVocab",
  async (_id: string) => {

    await vocab.delete(_id);
    return _id; 
  }
);
export const resetVocabDetail = createAsyncThunk(
  "vocab/resetVocabDetail",
  async () => {
    return null; // This will trigger resetting vocabDetail to initialState
  }
);
export const updateVocab = createAsyncThunk(
  "get/updateVocab",
  async ({ id, formData }: { id: string; formData: FormData }) => {
    const { data } = await vocab.put(id, formData); // Pass id and formData separately
    return data;
  }
);

const initialState: VocabState = {
  vocabList: [],
  totalVocab:0,
  vocabDetail:{
    _id:"",
    antonym:"",
    category:[
      {
        _id:"",
        name:""
      }
    ],
    context:"",
    example:"",
    image:"",
    meaning:"",
    name:"",
    note:"",
    phrase:"",
    synonym:"",
    topic:[{
      _id:"",
      name:'',
    }],
    transcription:"",
    user:"",
    isRemember:false
  }

};

const wordformSlice = createSlice({
  name: "vocab",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createVocab.fulfilled, (state, action) => {
      state.vocabList.push(action.payload);
   
    });
    
    builder.addCase(getVocab.fulfilled, (state, action) => {

      state.vocabList = action.payload.vocabs;

    });
    builder.addCase(updateVocab.fulfilled, (state, action) => {
      state.vocabList = action.payload.vocabs;

    });
    builder.addCase(getVocabQuiz.fulfilled, (state, action) => {

      state.vocabList = action.payload.vocab;

    });
    builder.addCase(getVocabByUserId.fulfilled, (state, action) => {
      state.vocabList = action.payload.vocab;
      state.totalVocab = action.payload.totalVocab;

    });
    builder.addCase(deleteVocab.fulfilled, (state, action) => {
      state.vocabList = state.vocabList.filter((item) => item._id !== action.payload);
    }); 
    builder.addCase(getVocabById.fulfilled, (state, action) => {
      state.vocabDetail = action.payload.vocab;
    }); 
    builder.addCase(resetVocabDetail.fulfilled, (state) => {
      state.vocabDetail = initialState.vocabDetail; // Reset vocabDetail to initial state
    });
  },
});

export default wordformSlice.reducer;
